import { createSlice } from '@reduxjs/toolkit';
import { fetchRoleList, roleCreate, roleDelete, roleUpdate } from './role-thunk';
import { IRole } from './types';
import toast from "react-hot-toast";

type InitialState = {
    isLoading: boolean;
    roleList: {
        totalCount: number,
        roles: IRole[]
    }
};

const initialState: InitialState = {
    isLoading: false,
    roleList: {
        totalCount: 0,
        roles: []
    },
};

const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoleList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchRoleList.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                state.roleList = payload?.data;
            })
            .addCase(fetchRoleList.rejected, (state, { payload }: any) => {
                state.isLoading = false;
            })
            .addCase(roleCreate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(roleCreate.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                toast.success(payload.message as string || "Role Created Successfully.")
            })
            .addCase(roleCreate.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "Role Create Failed.")
            })
            .addCase(roleUpdate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(roleUpdate.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
            })
            .addCase(roleUpdate.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "Role Update Failed.")
            })
            .addCase(roleDelete.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(roleDelete.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
            })
            .addCase(roleDelete.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "Role Delete Failed.")
            })
    },
});

export const roleReducer = roleSlice.reducer;