import { createSlice } from '@reduxjs/toolkit';
import { adminDelete, loginAdmin, registerAdmin, updateAdmin } from './auth-thunk';
import toast from 'react-hot-toast';

const initialState = {
    isLoading: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loginAdmin.fulfilled, (state, { payload }) => {
                toast.success((payload).message || 'Login successfully.')
                state.isLoading = false;
            })
            .addCase(loginAdmin.rejected, (state, { payload }) => {
                toast.error((payload as Error).message || 'Register Failed')
                state.isLoading = false;
            })
            .addCase(registerAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(registerAdmin.fulfilled, (state, { payload }) => {
                toast.success((payload).message || 'Register successfully.')
                state.isLoading = false;
            })
            .addCase(registerAdmin.rejected, (state, { payload }) => {
                toast.error((payload as Error).message || 'Register Failed')
                state.isLoading = false;
            })
            .addCase(updateAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateAdmin.fulfilled, (state, { payload }) => {
                toast.success((payload).message || 'Updated successfully.')
                state.isLoading = false;
            })
            .addCase(updateAdmin.rejected, (state, { payload }) => {
                toast.error((payload as Error).message || 'Update Failed')
                state.isLoading = false;
            })
            .addCase(adminDelete.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(adminDelete.fulfilled, (state, { payload }) => {
                toast.success((payload).message || 'Deleted successfully.')
                state.isLoading = false;
            })
            .addCase(adminDelete.rejected, (state, { payload }) => {
                toast.error((payload as Error).message || 'Delete Failed')
                state.isLoading = false;
            });
    },
});

export const authReducer = authSlice.reducer;