import React, { forwardRef } from 'react';
import { Avatar, IconButton, Menu, MenuItem, Typography, Box } from '@mui/material';
import { useState } from 'react';
import { handleLogout } from "../helper";

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    image: string;
    email: string;
    icon?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
    ({ image, email, icon, onClick, ...others }: UserButtonProps, ref) => {
        return (
            <div>

                <IconButton
                    ref={ref}
                    style={{
                        width: 'fit-content',
                        height: 'fit-content',
                        padding: '5px',
                        color: 'black',
                        borderRadius: '4px',
                    }}
                    onClick={onClick}
                >
                    <Box display="flex" alignItems="center">
                        <Avatar src={image} />
                    </Box>
                </IconButton>
            </div>
        )
    }
);

export default function ProfileOption() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutHandler = () => {
        handleClose();
        handleLogout()
    };

    return (
        <>
            <UserButton
                image="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-8.png"
                email="hspoonlicker@outlook.com"
                onClick={handleClick}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                <MenuItem onClick={handleLogoutHandler}>Logout</MenuItem>
            </Menu>
        </>
    );
}
