import { createSlice } from '@reduxjs/toolkit';
import { fetchProductsList, ProductCreate, ProductDelete, ProductUpdate } from './product-thunk';
import { IProduct } from './types';
import toast from "react-hot-toast";

type InitialState = {
    isLoading: boolean;
    productsList: {
        products: IProduct[];
        totalCount: number
    }
};

const initialState: InitialState = {
    isLoading: false,
    productsList: { products: [], totalCount: 0 },
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductsList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchProductsList.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                state.productsList = payload;
            })
            .addCase(fetchProductsList.rejected, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(ProductCreate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(ProductCreate.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                toast.success(payload.message as string || "Product Created Successfully.")
            })
            .addCase(ProductCreate.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "Product Create Failed.")
            })
            .addCase(ProductUpdate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(ProductUpdate.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                toast.success(payload.message as string || "Product Updated Successfully.")
            })
            .addCase(ProductUpdate.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "Product Update Failed.")
            })
            .addCase(ProductDelete.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(ProductDelete.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                toast.success(payload.message as string || "Product Deleted Successfully.")
            })
            .addCase(ProductDelete.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "Product Delete Failed.")
            })
    },
});

export const productReducer = productSlice.reducer;