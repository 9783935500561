import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_MANUFACTURE_LIST_URL, FETCH_MANUFACTURE_REJECT_URL, ReturnStatusType } from '../../constants';
import { IManufacturer } from './types';
import axiosInstance from '../../services/axios-instance';
import { IRejectSchema } from '../../helper/validation';

interface fetchUserListResponse {
  status: number;
  message: string;
  data: {
    manufacturers: IManufacturer[];
    totalCount:number;
  };
  error: Record<string, unknown>;
}

interface PaginationData {
  offset: number;
  limit: number;
  status?: number;
  search?: string;
  startDate?: string;
  endDate?: string;
}

interface IRejectReturnOrder {
  id: string;
  data: {
    vendorOpenPackageDescription?:string
    manufacturerAcceptedTime?:string
    images?: any[];
    status:string;
  }
}

export const fetchManufacturerList = createAsyncThunk<fetchUserListResponse, PaginationData>(
  'manufacturer/manufacturers',
  async (data: PaginationData, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams(data as any).toString(); // Convert data to query parameters
      const response: any = await axiosInstance.get(
        FETCH_MANUFACTURE_LIST_URL +"?"+ queryParams
      );
      if (response.data) {
        return response.data; 
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateReturnOrder = createAsyncThunk<fetchUserListResponse, IRejectReturnOrder>(
  'return/reject',
  async ({id,data}: IRejectReturnOrder, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.put(
        FETCH_MANUFACTURE_REJECT_URL + id,
        data
      );
      if (response.data) {
        return response.data;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);