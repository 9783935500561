import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { userExists } from '../services/request-helper';
import CustomizeLoader from '../components/loader';
import ResponsiveSizes from '../shared/app-shell-layout';

const LogIn = React.lazy(() => import('../pages/auth/login'));
const Users = React.lazy(() => import('../pages/users/view'));
const Roles = React.lazy(() => import('../pages/roles/view'));
const Admin = React.lazy(() => import('../pages/admin/view'));
const Category = React.lazy(() => import('../pages/category/view'));
const Products = React.lazy(() => import('../pages/products/view'));
const Manufacturer = React.lazy(() => import('../pages/manufacturer/view'));

interface PrivateRouteProps {
    element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
    return userExists() ? (
        element
    ) : (
        <Navigate to="/login" replace />
    );
};

const RoutesMain: React.FC = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<CustomizeLoader />}>
                <Routes>
                    <Route path="/login" element={<LogIn />} />
                    <Route path="/" element={<PrivateRoute element={<ResponsiveSizes />} />} >
                        <Route path="/" element={<h1>Dashboard</h1>} />
                        <Route path="/dashboard" index element={<h1>Dashboard</h1>} />
                        <Route path="/admins" element={<Admin />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/roles" element={<Roles />} />
                        <Route path="/category" element={<Category />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/manufacturer" element={<Manufacturer />} />
                        <Route path="*" element={<h1>404 not found.</h1>} />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default RoutesMain;
