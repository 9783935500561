import { Box, CircularProgress, useTheme } from "@mui/material";

export default function CustomizeLoader() {
    const theme = useTheme()
    return (
        <Box sx={{ height: "100vh", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
            <Box sx={{ position: 'relative', }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: theme.palette.grey[200],
                    }}
                    size={40}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    color="primary"
                    sx={{
                        animationDuration: '550ms',
                        position: 'absolute',
                        left: 0,
                        strokeLinecap: 'round'
                    }}
                    size={40}
                    thickness={4}
                />
            </Box>
        </Box>
    )
}