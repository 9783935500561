import { useSelector } from "react-redux";
import useAppDispatch from "../../hooks/useApiDispatch";
import { selectThemeName } from "../../redux/theme/theme-selector";
import { setTheme } from "../../redux/theme/theme-slice";
import { Button, IconButton } from "@mui/material";
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import Brightness3Icon from '@mui/icons-material/Brightness3';

const ThemeSwitcher = () => {
    const dispatch = useAppDispatch();
    const themeName = useSelector(selectThemeName);
    const icon = themeName === "darkTheme" ? <Brightness3Icon /> : <BrightnessMediumIcon />
    const toggleTheme = () => {
        const newTheme = themeName === 'lightTheme' ? 'darkTheme' : 'lightTheme';
        dispatch(setTheme(newTheme));
    };

    return (
        <IconButton color="inherit" onClick={toggleTheme} >
            {icon}
        </IconButton>
    );
};

export default ThemeSwitcher;