import { createAsyncThunk } from '@reduxjs/toolkit';
import { CREATE_CUSTOMER_URL, FETCH_CUSTOMER_LIST_URL } from '../../constants';
import { ICustomer } from './types';
import axiosInstance from '../../services/axios-instance';
import { IManufactureSchema } from "../../helper/validation";

interface fetchUserListResponse {
  status: number;
  message: string;
  data: {
    users: ICustomer[];
  };
  error: Record<string, unknown>;
}

interface PaginationData {
  page: number;
  limit: number;
  search?: string;
  roleId?: string;
}


export const fetchUserList = createAsyncThunk<fetchUserListResponse, PaginationData>(
  'user/users',
  async (data: PaginationData, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        FETCH_CUSTOMER_LIST_URL,
        data
      );
      if (response.status === 200) {
        // data.handleRedirection(response.data);
        return response; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const userCreate = createAsyncThunk<fetchUserListResponse, { data: IManufactureSchema, handleRefresh: () => void }>(
  'user/create',
  async ({ data, handleRefresh }: { data: IManufactureSchema, handleRefresh: () => void }, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        CREATE_CUSTOMER_URL,
        data
      );

      if (response.data) {
        handleRefresh()
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);