import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ThemeState = {
    themeName: 'lightTheme' | 'darkTheme';
};

const initialState: ThemeState = {
    themeName: (localStorage.getItem('theme') as 'lightTheme' | 'darkTheme') || 'lightTheme',
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<'lightTheme' | 'darkTheme'>) => {
            state.themeName = action.payload;
            localStorage.setItem('theme', action.payload);
        },
    },
});

export const { setTheme } = themeSlice.actions;
export const themeReducer = themeSlice.reducer;