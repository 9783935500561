import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_ADMIN_LIST_URL, FETCH_CATEGORY_ADD_URL, FETCH_CATEGORY_ALL_URL, FETCH_CATEGORY_DELETE_URL, FETCH_CATEGORY_EDIT_URL, FETCH_CATEGORY_LIST_URL } from '../../constants';
import { ICategory } from './types';
import axiosInstance from '../../services/axios-instance';
import { ICategorySchema } from "../../helper/validation";

interface fetchListResponse {
  status: number;
  message: string;
  data: {
    category: ICategory[];
  };
  error: Record<string, unknown>;
}

interface PaginationData {
  page: number;
  limit: number;
  search?: string;
}

interface ICategoryUpdate {
  id: string,
  data: ICategorySchema, handleRefresh: () => void
}

interface IcategoryDelete {
  id: string,
  handleRefresh: () => void
}


export const fetchCategoryListALL = createAsyncThunk<fetchListResponse>(
  'category/categories',
  async (data, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.get(
        FETCH_CATEGORY_ALL_URL
      );
      if (response.data) {
        return response; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchCategoryList = createAsyncThunk<fetchListResponse, PaginationData>(
  'category/get-all',
  async (data: PaginationData, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        FETCH_CATEGORY_LIST_URL,
        data
      );
      if (response.status === 200) {
        return response; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const categoryCreate = createAsyncThunk<fetchListResponse, { data: ICategorySchema, handleRefresh: () => void }>(
  'category/create',
  async ({ data, handleRefresh }: { data: ICategorySchema, handleRefresh: () => void }, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        FETCH_CATEGORY_ADD_URL,
        data
      );

      if (response.data) {
        handleRefresh()
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const categoryUpdate = createAsyncThunk<fetchListResponse, ICategoryUpdate>(
  'category/update',
  async ({ id, data, handleRefresh }: ICategoryUpdate, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.put(
        FETCH_CATEGORY_EDIT_URL + id,
        data
      );

      if (response.data) {
        handleRefresh()
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const categoryDelete = createAsyncThunk<fetchListResponse, IcategoryDelete>(
  'category/delete',
  async ({ id, handleRefresh }: IcategoryDelete, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.delete(
        FETCH_CATEGORY_DELETE_URL + id,
      );
      if (response.status === 200) {
        handleRefresh()
        return response; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
