// Check if user logged in or not
export const userExists = () => {
   return checkAuthentication();
};

export const getToken = (): string => {
    const token = getCookie('token') ?? '';
    return token;
}

const checkAuthentication = (): boolean => {
    const token = getCookie('token'); // Implement a function to get the token from the cookie
    // Add your token validation logic here
    return token ? true : false; // Example: Return true if token exists, false otherwise
};

const getCookie = (name: string): string | undefined => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) return parts.pop()?.split(';').shift();
};
