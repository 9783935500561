import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_ADMIN_DELETE_URL, FETCH_ADMIN_EDIT_URL, LOGIN_URL, REGISTER_URL } from '../../constants';
import axiosInstance from '../../services/axios-instance';
import toast from 'react-hot-toast';
import { IRegisterSchema } from '../../helper/validation';

interface LoginData {
  data: {
    email: string;
    password: string;
  },
  handleRedirection: (response: any) => void;
}

interface RegisterData {
  data: IRegisterSchema,
  handleRedirection: (response: any) => void;
}

interface IAdminUpdate { id: string, data: IRegisterSchema, handleRefresh: () => void }
interface IAdminDelete { id: string, handleRefresh: () => void }

interface ApiResponse {
  message: string;
  status: string;
  // Add other properties of the response object as needed
}

export const loginAdmin = createAsyncThunk<ApiResponse, LoginData>(
  'auth/loginAdmin',
  async (data: LoginData, { rejectWithValue }) => {
    const apiCallData = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axiosInstance.post(
        LOGIN_URL,
        data.data, // Pass data directly
        apiCallData
      );

      if (response.data.token) {
        data.handleRedirection(response.data);
        return response.data; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error:any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const registerAdmin = createAsyncThunk<ApiResponse, RegisterData>(
  'auth/registerAdmin',
  async (data: RegisterData, { rejectWithValue }) => {
    try {
      const response:any = await axiosInstance.post(
        REGISTER_URL,
        data.data
      );
      if (response.status === 200) {
        data.handleRedirection(response.data);
        return response.data; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error:any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const updateAdmin = createAsyncThunk<ApiResponse, IAdminUpdate>(
  'admin/update',
  async ({ id, data, handleRefresh }: IAdminUpdate, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        FETCH_ADMIN_EDIT_URL + id,
        data
      );
      if (response.status === 200) {
        handleRefresh();
        return response; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const adminDelete = createAsyncThunk<ApiResponse, IAdminDelete>(
  'admin/delete',
  async ({ id, handleRefresh }: IAdminDelete, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.delete(
        FETCH_ADMIN_DELETE_URL + id
      );
      if (response.status === 200) {
        handleRefresh()
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);