export const BASE_URL = 'https://rma.buynowdepot.com/api'
// admin-auth
export const LOGIN_URL = '/admin/login';
export const REGISTER_URL = '/admin/register';
export const FETCH_ADMIN_EDIT_URL = '/admin/edit/';
export const FETCH_ADMIN_DELETE_URL = '/admin/delete/';
export const FETCH_ADMIN_LIST_URL = '/admin/get/all';
// users
export const FETCH_CUSTOMER_LIST_URL = '/user/get/all';
export const CREATE_CUSTOMER_URL = '/user/register';
// roles
export const FETCH_ROLE_LIST_URL = '/admin/role/get/all';
export const FETCH_ROLE_ADD_URL = '/admin/role/add';
export const FETCH_ROLE_EDIT_URL = '/admin/role/edit/';
export const FETCH_ROLE_DELETE_URL = '/admin/role/delete/';
// category
export const FETCH_CATEGORY_LIST_URL = '/category/get/all'; //post
export const FETCH_CATEGORY_ALL_URL = '/category/'; //get
export const FETCH_CATEGORY_ADD_URL = '/category/create'; //post
export const FETCH_CATEGORY_EDIT_URL = '/category/update/'; //put
export const FETCH_CATEGORY_DELETE_URL = '/category/delete/'; //delete
// products
export const FETCH_PRODUCT_ADD_URL = '/category/create'; //post
export const FETCH_PRODUCT_EDIT_URL = '/category/update/'; //put
export const FETCH_PRODUCT_DELETE_URL = '/category/delete/'; //delete
export const FETCH_PRODUCT_ALL_URL = '/product/get/all'; //get
// users
export const FETCH_MANUFACTURE_LIST_URL = '/return/all-udid-item/all';
export const FETCH_MANUFACTURE_REJECT_URL = '/return/';

export const PAGINATION = {
    START_PAGE: 0,
    PER_PAGE: [5, 10, 20]
}

export const ROLES_ID = {
    CONSUMER: "1",
    VENDOR: "2",
    MANUFACTURE: "3",
    ADMIN: "4",
}

export const ROLES_NAME : {[key:string]:{id:number,name:string}} = {
    [ROLES_ID.CONSUMER]: {
        id: 1,
        name: "Consumer"
    },
    [ROLES_ID.VENDOR]: {
        id: 2,
        name: "Vendor"
    },
    [ROLES_ID.MANUFACTURE]: {
        id: 3,
        name: "Manufacturer"
    },
    [ROLES_ID.ADMIN]: {
        id: 4,
        name: "Admin"
    },
};


export const PERMISSIONS = [
    { id: 1, name: 'User Management', type: 'UserManagement', permissions: [true, false, true, false, false] },
    { id: 2, name: 'Admin Management', type: 'AdminManagement', permissions: [true, false, true, false, false] },
    { id: 3, name: 'Role Management', type: 'RoleManagement', permissions: [true, false, true, false, false] },
]

export const RETURN_STATUS_TYPE = {
    PENDING: 0,
    ACCEPTED: 2,
    REJECTED: 3
} as const; // Use 'as const' to infer literal types

export type ReturnStatusType = typeof RETURN_STATUS_TYPE[keyof typeof RETURN_STATUS_TYPE]; // Create a type for the enum keys

export const RETURN_STATUS: Record<ReturnStatusType, string> = {
    [RETURN_STATUS_TYPE.PENDING]: "Pending",
    [RETURN_STATUS_TYPE.ACCEPTED]: "Accepted",
    [RETURN_STATUS_TYPE.REJECTED]: "Rejected"
};

export const RETURN_STATUS_TYPE_OPTIONS = [
    {
        label: RETURN_STATUS[RETURN_STATUS_TYPE.PENDING],
        value: String(RETURN_STATUS_TYPE.PENDING)
    },
    {
        label: RETURN_STATUS[RETURN_STATUS_TYPE.ACCEPTED],
        value: String(RETURN_STATUS_TYPE.ACCEPTED)
    },
    {
        label: RETURN_STATUS[RETURN_STATUS_TYPE.REJECTED],
        value: String(RETURN_STATUS_TYPE.REJECTED)
    },
]

