import { createSlice } from '@reduxjs/toolkit';
import { fetchUserList, userCreate } from './customer-thunk';
import { ICustomer } from './types';
import toast from "react-hot-toast";

type InitialState = {
    isLoading: boolean;
    customerList: {
        users: ICustomer[];
        totalCount: number
    }
};

const initialState: InitialState = {
    isLoading: false,
    customerList: { users: [], totalCount: 0 },
};

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUserList.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                state.customerList = payload?.data;
            })
            .addCase(fetchUserList.rejected, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(userCreate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userCreate.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                toast.success(payload.message as string || "User Created Successfully.")
            })
            .addCase(userCreate.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "User Create Failed.")
            })
    },
});

export const customerReducer = customerSlice.reducer;