import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_ROLE_ADD_URL, FETCH_ROLE_DELETE_URL, FETCH_ROLE_EDIT_URL, FETCH_ROLE_LIST_URL } from '../../constants';
import { IPermission, IRole } from './types';
import axiosInstance from '../../services/axios-instance';

interface fetchRoleListResponse {
  status: number;
  message: string;
  data: IRole[];
  error: Record<string, unknown>;
}

interface PaginationData {
  search?: string;
}

interface IRoleData {
  data: {
    name: string;
  };
  handleRefresh: () => void
}

interface IRoleDataUpdate {
  id: string;
  data: {
    name?: string;
    permissions?: IPermission[];
  };
  handleRefresh: () => void
}
interface IRoleDataDelete {
  id: string;
  handleRefresh: () => void
}


export const fetchRoleList = createAsyncThunk<fetchRoleListResponse, PaginationData>(
  'role/roles',
  async (data: PaginationData, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        FETCH_ROLE_LIST_URL,
        data
      );
      if (response.status === 200) {
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const roleCreate = createAsyncThunk<fetchRoleListResponse, IRoleData>(
  'role/create',
  async ({ data, handleRefresh }: IRoleData, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        FETCH_ROLE_ADD_URL,
        data
      );
      if (response.status === 200) {
        handleRefresh()
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const roleUpdate = createAsyncThunk<fetchRoleListResponse, IRoleDataUpdate>(
  'role/update',
  async ({ id, data, handleRefresh }: IRoleDataUpdate, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        FETCH_ROLE_EDIT_URL + id,
        data
      );
      if (response.status === 200) {
        handleRefresh()
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const roleDelete = createAsyncThunk<fetchRoleListResponse, IRoleDataDelete>(
  'role/delete',
  async ({ id, handleRefresh }: IRoleDataDelete, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.delete(
        FETCH_ROLE_DELETE_URL + id
      );
      if (response.status === 200) {
        handleRefresh()
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);