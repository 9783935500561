import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: "#006ddb",
        },
        background: {
            default: "#f3f6f9",
            paper: "#ffffff",
        },
        text: {
            primary: "#000000",
            secondary: "#333333",
        },
        divider: "#0000001f",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        },
    },
});

export default theme;