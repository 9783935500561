import React, { useEffect, useState } from 'react';
import { AppBar, Box, CssBaseline, Drawer, IconButton, List, ListItem,ListItemText, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import ProfileOption from './profile-option';
import { IoMdNotifications } from "react-icons/io";
import { FaHome, FaUserShield } from "react-icons/fa";
import MenuIcon from '@mui/icons-material/Menu';
import '../styles/shared.css';
import ThemeSwitcher from '../components/theme-switcher';
import { FaUsers } from "react-icons/fa6";
import { BsPeopleFill } from "react-icons/bs";
import { TbCategoryFilled } from "react-icons/tb";
import { AiFillProduct } from "react-icons/ai";

const drawerWidth = 290;

const navOptions = [
    { icon: <FaHome size={18} className='menu-icon' />, label: "Dashboard", path: '/dashboard' },
    { icon: <FaUserShield size={18} className='menu-icon' />, label: "Admins", path: '/admins' },
    { icon: <FaUsers size={18} className='menu-icon' />, label: "Users", path: '/users' },
    { icon: <BsPeopleFill size={18} className='menu-icon' />, label: "Roles", path: '/roles' },
    { icon: <TbCategoryFilled size={18} className='menu-icon' />, label: "Category", path: '/category' },
    { icon: <AiFillProduct size={18} className='menu-icon' />, label: "Products", path: '/products' },
    { icon: <AiFillProduct size={18} className='menu-icon' />, label: "Manufacturer", path: '/manufacturer' },
];

export default function ResponsiveSizes() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const [desktopOpen, setDesktopOpen] = useState(true);
    const [activePath, setActivePath] = useState('');

    const handleDrawerToggle = () => {
        if (isMobile) {
            setMobileOpen(!mobileOpen);
        } else {
            setDesktopOpen(!desktopOpen)
        }
    };

    useEffect(() => {
        if (window.location.pathname !== activePath) {
            setActivePath(window.location.pathname)
        }
    }, [window.location.pathname])


    const drawer = (
        <div>
            <Box display="flex" justifyContent="center" p={2}>
                <Typography variant="h5" noWrap component="h5" sx={{ fontSize: "28px", flexGrow: 1, letterSpacing: 2, fontFamily: "poppins", fontWeight: 600 }}>
                    RMA
                </Typography>
            </Box>
            <List>
                {navOptions.map((item, index) => {
                    const isActive = item.path === activePath
                    return (
                        <Link to={item.path} onClick={() => setActivePath(item.path)} style={{ textDecoration: 'none', color: 'unset', padding: "0 10px", display: "block" }} key={index}>
                            <ListItem sx={{
                                width: "100%",
                                pl: 2,
                                py: 1,
                                gap: 2,
                                pr: 1.5,
                                borderRadius: 0.75,
                                typography: 'body2',
                                fontWeight: 'fontWeightMedium',
                                color: theme.palette.text.primary,
                                opacity: 0.8,
                                ...(isActive && {
                                    backgroundColor: theme.palette.primary.main + '1c',
                                    fontWeight: 700,
                                    typography: 'h6',
                                    opacity: 1,
                                    color: "inherit"
                                }),
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.main + '1c',
                                },
                            }}>
                                {item.icon}
                                <ListItemText primary={item.label} />
                            </ListItem>
                        </Link>
                    )
                })}
            </List>
        </div>
    );


    return (
        <Box display="flex" width="100%" height="100vh">
            <CssBaseline />
            <Box
                component="nav"
                sx={{ width: { md: desktopOpen || isMobile ? drawerWidth : '0px' }, flexShrink: { md: 0 }, display: desktopOpen || isMobile ? 'block' : 'none' }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant={isMobile || desktopOpen ? "temporary" : "permanent"}
                    open={isMobile ? mobileOpen : desktopOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}

                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        // backgroundColor: theme.palette.primary.main
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box width="100%" display="flex" flexDirection="column" height="100vh">
                <AppBar position="sticky" sx={{ zIndex: theme.zIndex.drawer + 1, backgroundColor: theme.palette.background.paper, boxShadow: "none", color: theme.palette.text.primary, borderBottom: `1px solid ${theme.palette.divider}` }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Dashboard
                        </Typography>
                        <ThemeSwitcher />
                        <IconButton color="inherit">
                            <IoMdNotifications size={25} />
                        </IconButton>
                        <ProfileOption />
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, flex: 1, p: 3, backgroundColor: "#e9e5ed1f", height: "100%" }}
                >
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

