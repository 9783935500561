import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: "#006ddb",
        },
        background: {
            default: "#07192e",
            paper: "#001e3c",
        },
        text: { 
            primary: "#ffffff",
            secondary: "#bbbbbb",
        },
        divider: "#ffffff1f",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536
        },
    },
});

export default theme;