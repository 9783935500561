import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminList } from './admin-thunk';
import { IAdmin } from './types';
import toast from "react-hot-toast";

type InitialState = {
    isLoading: boolean;
    adminList: {
        admins: IAdmin[];
        totalCount: number
    }
};

const initialState: InitialState = {
    isLoading: false,
    adminList: { admins: [], totalCount: 0 },
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAdminList.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                state.adminList = payload?.data;
            })
            .addCase(fetchAdminList.rejected, (state, { payload }) => {
                state.isLoading = false;
            })
    },
});

export const adminReducer = adminSlice.reducer;