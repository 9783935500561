// import logo from './logo.svg';
import './App.css';
import RoutesMain from './routes';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { selectThemeName } from './redux/theme/theme-selector';
import { getThemeByName } from './themes';

function App() {
  const themeName = useSelector(selectThemeName);
  const theme: Theme = getThemeByName(themeName);
  return (
    <ThemeProvider theme={theme}>
      <RoutesMain />
      <Toaster
        position="top-center"
        reverseOrder={true}
      />
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
