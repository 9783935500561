import { createSlice } from '@reduxjs/toolkit';
import { categoryCreate, categoryDelete, categoryUpdate, fetchCategoryList, fetchCategoryListALL } from './category-thunk';
import { ICategory } from './types';
import toast from "react-hot-toast";

type InitialState = {
    isLoading: boolean;
    categoryList: {
        category: ICategory[];
        totalCount: number
    },
    categoryALL: ICategory[]
};

const initialState: InitialState = {
    isLoading: false,
    categoryList: { category: [], totalCount: 0 },
    categoryALL: [],
};

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategoryList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCategoryList.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                state.categoryList = payload?.data;
            })
            .addCase(fetchCategoryList.rejected, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(fetchCategoryListALL.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCategoryListALL.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                state.categoryALL = payload?.data;
            })
            .addCase(fetchCategoryListALL.rejected, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(categoryCreate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(categoryCreate.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                toast.success(payload.message as string || "Category Created Successfully.")
            })
            .addCase(categoryCreate.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "Category Create Failed.")
            })
            .addCase(categoryUpdate.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(categoryUpdate.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                toast.success(payload.message as string || "Category Updated Successfully.")
            })
            .addCase(categoryUpdate.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "Category Update Failed.")
            })
            .addCase(categoryDelete.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(categoryDelete.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                toast.success(payload.message as string || "Category Deleted Successfully.")
            })
            .addCase(categoryDelete.rejected, (state, { payload }: any) => {
                state.isLoading = false;
                toast.error(payload.message as string || "Category Delete Failed.")
            })
    },
});

export const categoryReducer = categorySlice.reducer;