import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { BASE_URL } from '../constants';
import { handleLogout } from '../helper';
import { getToken, userExists } from './request-helper';

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (userExists() && config.headers) {
        config.headers['Authorization'] = getToken();
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Interceptor to handle response status
axiosInstance.interceptors.response.use((response: AxiosResponse | any) => {
    if (response.status === 410) {
        handleLogout();
    }
    return response.data;
}, (error) => {
    return Promise.reject(error);
});


export default axiosInstance