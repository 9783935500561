import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_ADMIN_LIST_URL, FETCH_CATEGORY_ADD_URL, FETCH_CATEGORY_DELETE_URL, FETCH_CATEGORY_EDIT_URL, FETCH_PRODUCT_ADD_URL, FETCH_PRODUCT_ALL_URL, FETCH_PRODUCT_DELETE_URL, FETCH_PRODUCT_EDIT_URL } from '../../constants';
import { IProduct } from './types';
import axiosInstance from '../../services/axios-instance';
import { ICategorySchema } from "../../helper/validation";

interface fetchListResponse {
  status: number;
  message: string;
  data: {
    products: IProduct[];
  };
  error: Record<string, unknown>;
}

interface PaginationData {
  page: number;
  limit: number;
  search?: string;
}



interface IProductUpdate {
  id: string,
  data: any, handleRefresh: () => void
}

interface IProductDelete {
  id: string,
  handleRefresh: () => void
}


export const fetchProductsList = createAsyncThunk<fetchListResponse, PaginationData>(
  'product/products',
  async (data: PaginationData, { rejectWithValue }) => {
    try {
      const queryParams = new URLSearchParams(data as any).toString(); // Convert data to query parameters
      const response: any = await axiosInstance.get(
        FETCH_PRODUCT_ALL_URL + "?" + queryParams
      );
      if (response.status === 200) {
        return response.data; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const ProductCreate = createAsyncThunk<fetchListResponse, { data: any, handleRefresh: () => void }>(
  'product/create',
  async ({ data, handleRefresh }: { data: any, handleRefresh: () => void }, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        FETCH_PRODUCT_ADD_URL,
        data
      );

      if (response.data) {
        handleRefresh()
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const ProductUpdate = createAsyncThunk<fetchListResponse, IProductUpdate>(
  'product/update',
  async ({ id, data, handleRefresh }: IProductUpdate, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.put(
        FETCH_PRODUCT_EDIT_URL + id,
        data
      );

      if (response.data) {
        handleRefresh()
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


export const ProductDelete = createAsyncThunk<fetchListResponse, IProductDelete>(
  'product/delete',
  async ({ id, handleRefresh }: IProductDelete, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.delete(
        FETCH_PRODUCT_DELETE_URL + id,
      );
      if (response.status === 200) {
        handleRefresh()
        return response; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
