import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_ADMIN_LIST_URL } from '../../constants';
import { IAdmin } from './types';
import axiosInstance from '../../services/axios-instance';

interface fetchUserListResponse {
  status: number;
  message: string;
  data: {
    users: IAdmin[];
  };
  error: Record<string, unknown>;
}

interface PaginationData {
  page: number;
  limit: number;
  search?: string;
}


export const fetchAdminList = createAsyncThunk<fetchUserListResponse, PaginationData>(
  'admin/admins',
  async (data: PaginationData, { rejectWithValue }) => {
    try {
      const response: any = await axiosInstance.post(
        FETCH_ADMIN_LIST_URL,
        data
      );
      if (response.status === 200) {
        return response; // Return response.data instead of response
      } else {
        return rejectWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);