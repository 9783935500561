import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { authReducer } from './auth/auth-slice'; // Import authApi
import { combineReducers } from 'redux';
import { customerReducer } from './customer/customer-slice';
import { roleReducer } from "./role/role-slice";
import { adminReducer } from "./admin/admin-slice";
import { themeReducer } from './theme/theme-slice';
import { categoryReducer } from "./category/category-slice";
import { productReducer } from "./product/product-slice";
import { manufacturerReducer } from './manufacturer/manufacturer-slice';

const rootReducer = combineReducers({
    auth: authReducer,
    customer: customerReducer,
    admin: adminReducer,
    role: roleReducer,
    theme: themeReducer,
    category: categoryReducer,
    product: productReducer,
    manufacturer: manufacturerReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
export type AppDispatch = typeof store.dispatch;
