import { createSlice } from '@reduxjs/toolkit';
import { IManufacturer } from './types';
import { fetchManufacturerList, updateReturnOrder } from './manufacturer-thunk';

type InitialState = {
    isLoading: boolean;
   manufacturerList: {
        manufacturers: IManufacturer[];
        totalCount: number;
    }
};

const initialState: InitialState = {
    isLoading: false,
    manufacturerList: { manufacturers: [], totalCount: 0 },
};

const manufacturerSlice = createSlice({
    name: 'manufacturer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchManufacturerList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchManufacturerList.fulfilled, (state, { payload }: any) => {
                state.isLoading = false;
                state.manufacturerList.manufacturers = payload?.manufacturers;
                state.manufacturerList.totalCount = payload?.totalCount || 0;
            })
            .addCase(fetchManufacturerList.rejected, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(updateReturnOrder.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateReturnOrder.fulfilled, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(updateReturnOrder.rejected, (state, { payload }) => {
                state.isLoading = false;
            })
    },
});

export const manufacturerReducer = manufacturerSlice.reducer;